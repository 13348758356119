@font-face {
  font-family: "Circular Std Book";
  src: url("CircularStd-Book.woff2") format("woff2"),
    url("CircularStd-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Circular Std Light";
  src: url("CircularStd-Light.woff2") format("woff2"),
    url("CircularStd-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Circular Std Medium";
  src: url("CircularStd-Medium.woff2") format("woff2"),
    url("CircularStd-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Circular Std Bold";
  src: url("CircularStd-Bold.woff2") format("woff2"),
    url("CircularStd-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Circular Std Black";
  src: url("CircularStd-Black.woff2") format("woff2"),
    url("CircularStd-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
